import { add, remove, index, on } from 'martha'
import choozy from 'choozy'

import createCarousel from 'embla-carousel'

export default function create(node) {
  let { emblaNode, next, prev, btns } = choozy(node)

  const embla = createCarousel(emblaNode, { loop: true })

  let evs = [
    on(prev, 'click', () => embla.scrollPrev()),
    on(next, 'click', () => embla.scrollNext()),
    on(btns, 'click', ({ currentTarget: t }) => embla.scrollTo(index(t))),
  ]

  embla.on('select', () =>
    btns.forEach((btn, j) =>
      j === embla.selectedScrollSnap()
        ? add(btn, btn.dataset.active)
        : remove(btn, btn.dataset.active),
    ),
  )

  return {
    onSelect(callback) {
      embla.on('select', () => callback(embla.selectedScrollSnap()))
    },
    destroy() {
      embla.destroy()
      evs.map(off => off())
    },
  }
}

import { component } from 'picoapp'
import choozy from 'choozy'
import Matter from 'matter-js'
import { rect } from 'martha'

export default component((node, ctx) => {
  const { canvas } = choozy(node)
  const { Engine, World, Bodies, Body } = Matter
  const context = canvas.getContext('2d')
  const engine = Engine.create({})
  const cache = []

  World.add(engine.world, [
    Bodies.rectangle(0, canvas.height, canvas.width * 2, 1, {
      label: 'floor',
      isStatic: true,
    }),
    createMouse(canvas, engine),
  ])

  ctx.on('resize', ({ dpr }) => {
    node.rect = rect(node)
    canvas.width = node.rect.width * dpr
    canvas.height = node.rect.height * dpr

    const floor = engine.world.bodies.find(body => body.label === 'floor')

    Body.setPosition(floor, {
      x: 0,
      y: canvas.height,
    })

    Body.setVertices(floor, [
      { x: 0, y: canvas.height },
      { x: canvas.width * 2, y: canvas.height },
      { x: canvas.width * 2, y: canvas.height + 1 },
      { x: 0, y: canvas.height + 1 },
    ])
  })

  ctx.on('confetti:leave', (_, circle) => {
    const body = Bodies.circle(circle.x, circle.y, circle.radius, {
      restitution: 0.5,
      friction: 0.01,
      mass: 3,
    })

    World.add(engine.world, [body])
    cache.push({ circle, body })
  })

  ctx.on('tick', () => {
    Engine.update(engine)
    context.clearRect(0, 0, canvas.width, canvas.height)

    if (cache.length) {
      for (let l = cache.length - 1, i = l; i >= 0; i--) {
        const { circle, body } = cache[i]

        circle.x = body.position.x
        circle.y = body.position.y

        if (circle.x <= -circle.radius || circle.x >= canvas.width + circle.radius) {
          cache.splice(i, 1)
          World.remove(engine.world, body)
        } else {
          circle.draw(context)
        }
      }
    }
  })
})

function createMouse(canvas, engine) {
  const mouse = Matter.Mouse.create(canvas)
  const mouseConstraint = Matter.MouseConstraint.create(engine, {
    mouse,
    constraint: {
      stiffness: 0.1,
    },
  })

  // remove default scroll/wheel listeners setup by Matter mouse
  // so we can still scroll while hovering the footer
  mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel)

  mouseConstraint.mouse.element.removeEventListener(
    'DOMMouseScroll',
    mouseConstraint.mouse.mousewheel,
  )

  return mouseConstraint
}

import choozy from 'choozy'
import { add, on, remove } from 'martha'
import { component } from 'picoapp'
import astrochimp from 'astrochimp'

export default component((node, _ctx) => {
  const { form, message } = choozy(node)

  on(form, 'submit', ev => {
    ev.preventDefault()
    add(form, 'o50')
    astrochimp(
      'https://thiscorner.us20.list-manage.com/subscribe/post?u=9fb7516727b190a4316b2dc3d&id=3872338d31',
      {
        EMAIL: form.email.value,
      },
      (error, data) => {
        console.log(error, data)
        message.innerHTML =
          error?.msg ?? data?.msg.startsWith('Almost')
            ? 'Almost finished… Check your email!'
            : data?.msg
        form.remove()
        remove(message, 'hidden')
      },
    )
  })
})

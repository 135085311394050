import { component } from 'picoapp'
import choozy from 'choozy'
import { add, on, remove } from 'martha'
import html from '../lib/html'
import cx from 'nanoclass'

const get = url => fetch(url).then(res => res.json())
const post = (url, body) =>
  fetch(url, { body: JSON.stringify(body), method: 'POST' }).then(res => res.json())

export default component((node, ctx) => {
  const { form, list, msg } = choozy(node)

  get('/api/guestbook').then(submissions => {
    list.innerHTML = html`
      ${submissions
        .map(
          ({ name, message }, i) => html`
            <div
              class="${cx([
                'pb-40 mb-40 border-dashed border-forest border-b-[1px] js-submission',
                i > 3 && 'hidden',
              ])}"
            >
              ${message}<br /><br />
              — ${name}
            </div>
          `,
        )
        .join('')}
      ${submissions.length > 4
        ? html`<button
            class="underline hover:no-underline"
            onclick="(() => {
              document.querySelectorAll('.js-submission').forEach(el => el.classList.remove('hidden'))
              this.remove()
            })()"
          >
            View more
          </button>`
        : ``}
    `
  })

  on(form, 'submit', ev => {
    ev.preventDefault()

    add(form, 'opacity-65 pointer-events-none')

    post('/api/guestbook', {
      name: form.name.value,
      message: form.message.value,
    }).then(() => {
      remove(form, 'opacity-65')
      add(form, 'opacity-0')
      remove(msg, 'hidden')
    })
  })
})

import { component } from 'picoapp'
import { h, render } from 'preact'
import { useState } from 'preact/hooks'
import { Marqy } from 'marqy'
import cx from 'nanoclass'

export default component((node, ctx) => {
  render(
    <Marqy speed={0.8}>
      <div class="text-45 m:text-85 leading-120 text-forest pt-16 m:pt-43 pb-16 m:pb-39 pr-20 m:pr-28 flex items-center">
        <div class="px-20 m:px-38">1086 Haircuts</div>
        <Img className="w-78 m:w-113 h-49 m:h-72 -rotate-15" src="/images/scissors.png" alt="" />
        <div class="pl-30 pr-20 m:pr-73">24 Pop-Ups</div>
        <Img className="w-64 m:w-92 h-82 m:h-118" src="/images/aframe.png" alt="" />
        <div class="pl-30 m:pl-79 pr-20 m:pr-59">145 Little Cyclists Sold</div>
        <Img className="w-69 m:w-100 h-72 m:h-96" src="/images/cyclist.png" alt="" />
      </div>
    </Marqy>,
    node,
  )
})

function Img({ src, alt, className }) {
  const [loaded, setLoaded] = useState(false)

  return (
    <img
      src={src}
      onLoad={() => requestAnimationFrame(() => setLoaded(true))}
      alt={alt}
      class={cx([className, 'transition duration-500 ease-out-quint', !loaded && 'opacity-0'])}
    />
  )
}

import 'focus-visible'
import app from './app'
import raf from './lib/raf'
import loadFonts from './lib/loadFonts'
import { on, once, size, remove } from 'martha'

on(window, 'resize', resize)
on(document, 'mousemove', mousemove)

raf(app)

loadFonts(app.getState().fonts)
  .then(() => {
    app.mount()
    resize()
    let { dom } = app.getState()
    once(dom.body, 'transitionend', () => app.emit('enter:completed'))
    remove(dom.body, 'opacity-0')
  })
  .catch(console.log)

function resize() {
  app.emit('resize', size())
}

function mousemove({ clientX: mx, clientY: my }) {
  app.emit('mousemove', { mx, my })
}

import { component } from 'picoapp'
import { noop, on, remove } from 'martha'

export default component((node, ctx) => {
  let offLoad = noop
  let offEnd = noop

  ctx.on('enter:completed', () => {
    offLoad = on(node, 'load', () => {
      offLoad()
      offLoad = noop
      remove(node, 'opacity-0')
    })

    node.src = node.dataset.src
    node.removeAttribute('data-src')
  })

  return () => {
    offLoad()
    offEnd()
  }
})

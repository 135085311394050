import choozy from 'choozy'
import { add, remove, rect } from 'martha'
import { component } from 'picoapp'
import createCarousel from '../lib/carousel'

export default component((node, ctx) => {
  const { cardWrap, cards } = choozy(node)
  const instance = createCarousel(node)

  ctx.on('resize', () => {
    cardWrap.style.height = Math.max(...cards.map(card => rect(card).height)) + 'px'
  })

  instance.onSelect(i => {
    cards.forEach((card, j) => {
      j === i ? remove(card, 'opacity-0') : add(card, 'opacity-0')
    })
  })

  return () => {
    instance.destroy()
  }
})

import { component } from 'picoapp'
import choozy from 'choozy'
import gsap from 'gsap'
import { on, rect } from 'martha'

export default component((node, ctx) => {
  const { cards } = choozy(node)

  on(cards, 'click', ev => {
    if (ctx.getState().ww < 768) return

    const bounds = rect(ev.currentTarget.parentNode)
    const i = parseInt(ev.currentTarget.dataset.index)

    console.log(i)

    const temp = {
      x: node.scrollLeft,
    }

    gsap.to(temp, {
      x: i * (bounds.width - 60),
      duration: 0.7,
      ease: 'cubic.inOut',
      onUpdate() {
        node.scroll(temp.x, 0)
      },
    })
  })
})

import { component } from 'picoapp'
import choozy from 'choozy'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { rect } from 'martha'

gsap.registerPlugin(ScrollTrigger)

export default component((node, ctx) => {
  let { pages, mobileInner, mobilePages } = choozy(node)

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: node,
      scroller: '#main',
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
    },
    defaults: { duration: 1, ease: 'none', force3D: true },
  })

  ScrollTrigger.matchMedia({
    '(max-width: 767px)': () => {
      mobilePages.forEach((page, i) => {
        if (i < mobilePages.length - 1) {
          tl.fromTo(
            page,
            {
              rotateY: 0,
            },
            {
              rotateY: -135,
            },
            `mobilePage-${i}`,
          )
        }
      })
    },
    '(min-width: 768px)': () => {
      pages.forEach((page, i) => {
        if (i > 0 && i < pages.length - 1) {
          tl.set(page, { zIndex: pages.length - i })
          tl.fromTo(
            page,
            {
              rotateY: 0,
            },
            {
              rotateY: -180,
            },
            `page-${i}`,
          )
          tl.set(page, { zIndex: i + 1 })
        }
      })
    },
  })

  ctx.on('resize', ({ ww, wh }) => {
    if (ww >= 768) {
      gsap.set(node, {
        height: pages.reduce((acc, el) => acc + rect(el).height, 0),
      })
    } else {
      mobilePages.forEach(mobilePage => {
        if (mobilePage.hasAttribute('data-has-height')) return
        gsap.set(mobilePage, { height: wh })
      })

      gsap.set(node, {
        height: mobilePages.reduce((acc, el) => acc + rect(el).height, 0),
      })

      gsap.set(mobileInner, {
        height: Math.max(...mobilePages.map(mobilePage => rect(mobilePage).height)),
      })
    }

    tl.invalidate()
    ScrollTrigger.refresh()
  })
})

import { component } from 'picoapp'
import gsap from 'gsap'
import choozy from 'choozy'
import { rect } from 'martha'

export default component((node, ctx) => {
  let { word1, word2, title } = choozy(node)

  node.rect = rect(node)
  word1.rect = rect(word1)
  word2.rect = rect(word2)

  let tl = gsap.timeline({
    paused: true,
    repeat: -1,
    defaults: {
      duration: 2,
      ease: 'expo.inOut',
    },
  })

  ctx.on('resize', () => {
    tl.pause()
    tl.invalidate()

    gsap.set([word1, word2], { x: 0, y: 0 })

    node.rect = rect(node)
    word1.rect = rect(word1)
    word2.rect = rect(word2)

    tl.invalidate()
    tl.restart()
  })

  tl.set([word1, word2], { x: 0, y: 0 })
    .set(title, { opacity: 0 })
    .to(
      word1,
      {
        x: () => -word1.rect.x + node.rect.x,
        y: () => -word1.rect.y + node.rect.y,
      },
      'a+=0.5',
    )
    .to(
      word2,
      {
        x: () => ctx.getState().ww - word2.rect.x - word2.rect.width - node.rect.x,
        y: () => word2.rect.y - node.rect.y,
      },
      'a+=0.5',
    )
    .to(title, { opacity: 1 }, 'a+=0.5')
    .to(
      word1,
      {
        y: () => word1.rect.y - word1.rect.height - node.rect.y - node.rect.y / 2.5,
      },
      'b+=0.5',
    )
    .to(
      word2,
      {
        x: () => -word2.rect.x + node.rect.x,
      },
      'b+=0.5',
    )
    .to(
      word1,
      {
        y: () => -word1.rect.y + node.rect.y,
      },
      'c+=0.5',
    )
    .to(
      word2,
      {
        x: () => ctx.getState().ww - word2.rect.x - word2.rect.width - node.rect.x,
      },
      'c+=0.5',
    )
    .to(
      word1,
      {
        x: 0,
        y: 0,
      },
      'd+=0.5',
    )
    .to(
      word2,
      {
        x: 0,
        y: 0,
      },
      'd+=0.5',
    )
    .to(title, { opacity: 0 }, 'd+=0.5')
    .restart()
})

import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import scroll from './components/scroll'
import sticky from './components/sticky'
import logo from './components/logo'
import carousel from './components/carousel'
import eventsCarousel from './components/eventsCarousel'
import heroConfetti from './components/heroConfetti'
import footerConfetti from './components/footerConfetti'
import marquee from './components/marquee.jsx'
import timeline from './components/timeline'
import yearbook from './components/yearbook'
import guestbook from './components/guestbook'
import vh from './components/vh'
import newsletter from './components/newsletter'

const components = {
  lazy,
  scroll,
  sticky,
  logo,
  carousel,
  eventsCarousel,
  heroConfetti,
  footerConfetti,
  marquee,
  timeline,
  yearbook,
  guestbook,
  vh,
  newsletter,
}

const sizes = size()
const state = {
  ...sizes,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [{ family: 'Beatrice' }, { family: 'Pitch' }],
}

export default picoapp(components, state)

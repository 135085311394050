import { component } from 'picoapp'
import choozy from 'choozy'
import { rect } from 'martha'

export default component((node, ctx) => {
  const { canvas } = choozy(node)
  const context = canvas.getContext('2d')

  let circles = []

  ctx.on('tick', () => {
    if (!circles.length) return

    context.clearRect(0, 0, canvas.width, canvas.height)

    for (let i = 0; i < circles.length; i++) {
      const circle = circles[i]

      circle.draw(context)
      circle.update()

      // if circle falls offscreen
      if (circle.y - circle.radius >= canvas.height) {
        // reposition within hero for infinite confetti effect
        circle.x = Math.round(Math.random() * canvas.width)
        circle.y = -circle.radius * 2

        // broadcast new circle instance to footer
        ctx.emit(
          'confetti:leave',
          null,
          new Circle({
            radius: circle.radius,
            x: circle.x,
            y: circle.y,
            velocity: circle.velocity,
            fill: circle.fill,
          }),
        )
      }
    }
  })

  ctx.on('resize', ({ dpr }) => {
    node.rect = rect(node)
    canvas.width = node.rect.width * dpr
    canvas.height = node.rect.height * dpr

    circles = new Array(60).fill().map(
      () =>
        new Circle({
          radius: 20,
          x: Math.round(Math.random() * canvas.width),
          y: Math.round(Math.random() * canvas.height),
          velocity: Math.random() * 2 + 3,
          fill: [
            '#FAFAFA',
            '#FAFAFA',
            '#FAFAFA',
            '#053F34',
            '#053F34',
            '#FFDE8B',
            '#FFDE8B',
            '#FFDE8B',
            '#FFDE8B',
          ][Math.round(Math.random() * 8)],
        }),
    )
  })
})

class Circle {
  constructor({ radius, x, y, velocity, fill }) {
    this.radius = radius
    this.x = x
    this.y = y
    this.velocity = velocity
    this.fill = fill
  }

  update() {
    this.y += this.velocity
  }

  draw(ctx) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI)
    ctx.fillStyle = this.fill
    ctx.fill()
  }
}
